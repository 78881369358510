@media (max-width: 960px) {
    .mat-dialog-container[class] {
        width: 100vw !important;
    }

    .cdk-overlay-pane {
        max-width: unset !important;
        min-width: max-content !important;
    }

    .datasheet-content {
        margin-bottom: 30px;
    }
}
