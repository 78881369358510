@import "app/styles/mobile";
@import "app/styles/tailwind";
@import "app/styles/override";
@import "app/styles/quill-rich-text-editor.scss";
//@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
//@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import '@angular/cdk/overlay-prebuilt.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300&display=swap');
@import "app/styles/border";
@import "app/styles/text";
@import "app/styles/common/content-hover";

:root {

    --color-primary: #1E7BFA;
    --color-primary-light: #EEF5FF;
    --color-light-primary: #d9e6f9;
    --color-primary-dark: #0058D1;
    --color-primary-extra-light: #54A0FF;
    --color-primary-extra-light-effect: #54a0ff26;
    --color-primary-contrast: #fff;
    --color-primary-extra-dark: #3562FB;
    --color-primary-extra-light-primary: #2c6bfc;
    --color-primary-very-light-primary: #2d6dfe;

    --color-border-gray: #D6E8FF;
    --color-grba: rgba(8, 15, 52, 0.06);
    --color-purple-small-light: #9378DE;

    --color-pink: #CDEBFC;
    --color-light-extra-pink: #D0C5FF;
    --color-light-extra-white: #EAEEFF;
    --color-light-pink: #EAD1E1;
    --seattle-website-theme: linear-gradient(-45deg, #CDEBFC, #EAD1E1, #CDEBFC, #EAD1E1);
    --sydney-website-theme: linear-gradient(-45deg, #6D97FE, #FAF4FF, #D3F6FF);
    --barcelona-website-theme: linear-gradient(-45deg, #9E53FE, #02D6FE, #7CB4FE, #91FFFE);
    --singapore-website-theme: linear-gradient(-45deg, #D8A5FF, #89D8FF, #EDEEFF);
    --sanfrancisco-website-theme: linear-gradient(-45deg, #F16F80, #BA8FBB, #B4C9E7, #E1CADE);
    --chicage-website-theme: linear-gradient(-45deg, #0BE4FF, #3101FE, #119AF8);
    --talenlio-background-gradient-color: linear-gradient(90deg, rgba(52, 63, 253, 1) 44%,
        rgba(84, 236, 255, 1) 100%) no-repeat center;

    --color-ripple-state: #8872E0;
    --color-horizon: #D365A7;
    --color-slate-stone: #6C6C6C;
    --theme-card-chip-color-istanbul: #D9E1E7;
    --theme-card-chip-color-pune: #E9E7F0;
    --theme-card-chip-color-berlin: #EEE9EA;
    --theme-card-chip-color-nairobi: #EEE9EA;
    --them-card-chip-color-mimai: #E9ECE6;
    --theme-card-chip-color-paris: #EAE8EF;
    --theme-card-chip-color-tokyo: #E8EBE6;
    --theme-card-chip-color-mumbai: #E5E9EE;

    --theme-card-background-color-istanbul: #EFF7FF;
    --theme-card-background-color-pune: #FCFBFF;
    --theme-card-background-color-berlin: #FEFCFC;
    --theme-card-background-color-nairobi: #FEFCFC;
    --theme-card-background-color-mimai: #FEFFF9;
    --theme-card-background-color-paris: #FCFBFF;
    --theme-card-background-color-tokyo: #FEFFF9;
    --theme-card-background-color-mumbai: #F8FBFF;

    --website-primary-color: #3A3286;
    --website-secondary-color: #9568F6;

    --theme-card-sidebar-color-istanbul: #33455F;
    --theme-card-sidebar-color-pune: #4B3C6A;
    --theme-card-sidebar-color-berlin: #63484E;
    --theme-card-sidebar-color-nairobi: #FFFEFE;
    --theme-card-sidebar-color-mimai: #FFFEFD;
    --theme-card-sidebar-color-paris: #EFEDFE;
    --theme-card-sidebar-color-tokyo: #2B403A;
    --theme-card-sidebar-color-mumbai: #FBFCFD;

    --theme-card-sidebar-chip-color-istanbul: #46556E;
    --theme-card-sidebar-chip-color-pune: #5E4F78;
    --theme-card-sidebar-chip-color-berlin: #735A5F;
    --theme-card-sidebar-chip-color-nairobi: #EEEBEC;
    --theme-card-sidebar-chip-color-mimai: #E9ECE6;
    --theme-card-sidebar-chip-color-paris: #EAE8EF;
    --theme-card-sidebar-chip-color-tokyo: #40524B;
    --theme-card-sidebar-chip-color-mumbai: #E5E9EE;

    --color-secondary: #FFB700;
    --color-secondary-light: #FFEDBF;
    --color-secondary-dark: #E5AB14;
    --color-secondary-contrast: #fff;

    --dark-blue: #1a3066;
    --extra-dark-blue: #1B2F64;
    --dark-light-black: #546681;
    --dark-extra-light-black: #686781;
    --smooth-white: #E4F1FF;
    --light-rgba: #080f3408;
    --color-light-gray-black: #dfedff38;

    --color-tertiary: #33455F;
    --color-tertiary-light-dark: #485A73;
    --color-tertiary-light: #8D96A2;
    --color-tertiary-medium: #5A6F87;
    --color-tertiary-dark: #1A2C45;
    --color-tertiary-contrast: #fff;
    --color-extra-light-tertiary: #F1F7FF;
    --color-stroke-light: #E9F2FF;
    --sign-up-card: #f8faffe6;

    --chip-background-color: #F3F7FB;
    --color-light-purple-white: #E5EDFF;
    --chip-color: #8392A5;
    --list-items-color: #6B788B;
    --color-row-input-border: #DBDBDB;

    --color-success: #22C55E;
    --color-success-light: #BBF7D0;
    --color-success-medium-dark: #388041;
    --color-success-dark-light: #36C97F;
    --color-success-dark: #16A34A;
    --color-success-light-white: #F1FFFA;
    --color-success-dark-light-white: #06BA9D;
    --color-success-contrast: #fff;
    --color-success-extra-light: #EDFFEA;

    --color-yellow-green: #c1d732;
    --color-yellow-green-light: #f2fadf;
    --color-yellow: #FFC42D;
    --color-light-green: #E5F4D4;

    --color-warning: #F59E0B;
    --color-warning-light: #FFE6BB;
    --color-warning-extra-light: #FFF8EA;
    --color-warning-dark: #D97706;
    --color-warning-dark-light: #DBA464;
    --color-warning-contrast: #fff;

    --color-danger-primary: #FC615C;
    --color-danger-primary-light: #FFEFEF;
    --color-danger: #EF4444;
    --color-danger-light: #FCA5A5;
    --color-danger-extra-light: #ffd6d6;
    --color-danger-white-light: #FFF0F0;
    --color-danger-light-dark: #FF6565;
    --color-danger-dark: #DC2626;
    --color-danger-contrast: #fff;

    --color-sky: #32d7d7;
    --color-sky-light: #dffafa;

    --color-aqua: #32abd7;
    --color-aqua-light: #dfeffa;

    --color-blue-purple: #4232d7;
    --color-blue-purple-light: #eadffa;

    --color-copper: #B08536;
    --color-copper-purple-light: #FFF9EA;

    --color-purple: #954AB9;
    --color-light-purple: #EEEBFF;
    --color-purple-medium-dark: #593ba9;
    --color-purple-extra-dark: #4C3C6A;
    --color-purple-extra-light: #B9C9FF;
    --color-purple-dark: #7A6686;
    --color-purple-light: #A59DB4;
    --color-purple-white: #FCFBFF;
    --color-white: #FFF;
    --color-purple-white-dark: #F2F0F4;

    --color-green: #95B464;
    --color-green-extra-light: #E2FFF0;
    --color-green-extra-dark-light-white: #E9FDF8;
    --color-green-dark: #2B413A;
    --color-green-light: #55645F;
    --color-green-white-dark: #95A09C;
    --color-green-white: #F9FAF1;
    --color-green-extra-light-white: #FEFFFA;
    --color-green-extra-white: #F0F4F0;

    --color-brown: #CD6767;
    --color-brown-medium-dark: #71513d;
    --color-brown-dark: #63484E;
    --color-brown-light-dark: #8E6F6B;
    --color-brown-light: #B1A3A6;
    --color-brown-extra-light: #F4F3F0;
    --color-brown-white: #F4F3F0;
    --color-brown-white-light: #FFFCFC;

    --color-alice-blue: #F4F9FF;
    --color-light-wheat: #F5F1F2;

    --color-info: #314158;
    --color-info-light: #BAC0C7;
    --color-info-dark: #1A2C45;
    --color-info-contrast: #FFFF;

    --color-sub-heading: #9CAEC6;

    --color-black: #242424;
    --color-black-light: #555555;
    --color-black-extra-light: #5A6F87;
    --color-black-dark: #000000;
    --color-black-light-dark: #212121;
    --color-black-contrast: #001141;

    --color-white-slight-blue: #FCFDFF;
    --color-white-light: #FEFEFE;
    --color-white-slight-dark: #F0F2F4;
    --color-white-dark: #F6F6F6;
    --color-white-extra-dark: #F9FBFF;
    --color-white-contrast: #000000;
    --color-extra-light-white: #F7FAFE;
    --color-white-light-slight-dark: #F5F9FF;

    --color-extra-light-grey: #B5C6DC;
    --color-light-grey: #F0F0F0;
    --color-grey-light-dark: #9CAFC6;
    --color-grey-dark: #3D5371;
    --color-extra-grey-dark: #485F7E;
    --color-more-extra-grey-dark: #B2B2B6;
    --color-light-gray-extra: #757575;

    --color-white-blue-light: #F1F5FA;
    --color-pale-white-blue-light: #F8FAFF;
    --color-blue-extra-light: #EEF6FF;
    --color-white-blue-dark: #99A2AF;
    --color-blue-white-light: #EDF2F8;
    --color-blue-light: #EBF8FF;

    --color-divider: #DEEDFF;
    --color-stroke: #DFEDFF;
    --color-light-chip-gray: #E7ECF8;
    --color-light-gray: #858585;
    --color-orange: #FFC14F;
    --color-light-orange-dark: #D28229;
    --color-light-yellow-dark: #d2b629;
    --color-light-orange: #FA8D50;
    --color-light-orange-light: #FFDDB7;
    --color-light-orange-extra-light: #FFF3EE;
    --color-light-orange-light-dark: #FF834E;
    --color-light-yellow-light: #fff3b7;
    --color-light-yellow-extra-light: #FFF5E2;
    --color-light-yellow-extra-light-white: #FFF5DA;
    --color-light-white: #E5F0FF;
    --color-dialog-header-background: #FBFDFF;

    // rgba only
    --color-card-box-shadow: rgba(74, 58, 255, .12);
    --color-card-box-shadow-dark: rgba(17, 12, 46, 0.15);
    --color-background-extra-light-blur: rgba(223, 237, 255, 0.22);
    --color-background-light-blur: rgba(255, 255, 255, 0.5);
    --color-background-blur: rgba(255, 255, 255, 0.85);
    --color-background-extra-blur: rgba(255, 255, 255, 0.98);
    --color-stroke-shadow: rgba(223, 237, 255, 0.5);
    --color-selected-mat-row: rgba(243, 244, 246, 0.40);
    --color-table-border: rgba(82, 63, 105, .06);
    --color-light-text-grey: rgba(229 231 235);
    --color-transparent: rgba(255, 255, 255, 0);
    --card-box-shadow-dark: rgba(17, 12, 46, .15);
    --color-shadow-blue: #00968805;
    --color-light-gray-blue-shadow: #174AFF0F;

    --sidebar-width: 150px;

    --black-transparent-2: rgba(0, 0, 0, .2);
    --black-transparent-12: rgba(0, 0, 0, .12);
    --black-transparent-14: rgba(0, 0, 0, .14);

    --card-box-shadow: 0px 7px 14px 0px rgba(8, 15, 52, 0.03);
}

.tal-body-container {
    height: calc(100vh - 158px);
    overflow: auto;
}

.text-heading-primary {
    color: var(--color-grey-dark);
}

.text-heading-secondary {
    color: var(--color-grey-light-dark);
}

.text-grey {
    color: var(--color-light-text-grey);
}

.text-heading-tertiary {
    color: var(--color-tertiary-medium);
}

.text-white {
    color: #fff !important;
}

.text-heading {
    color: var(--color-tertiary);
}

.text-heading-light {
    color: var(--color-black-extra-light);
}

.text-primary {
    color: var(--color-primary) !important;
}

.text-success-dark-light {
    color: var(--color-success-dark-light);
}

.text-purple {
    color: var(--color-purple);
}

.text-purple-dark {
    color: var(--color-purple-medium-dark);
}

.text-tertiary {
    color: var(--color-tertiary);
}

.bg-purple-dark {
    background: var(--color-purple-medium-dark);
}

.bg-green-light {
    background: var(--color-green-extra-light);
}

.bg-yellow-light {
    background: var(--color-light-yellow-extra-light);
}

.bg-purple-light {
    background: var(--color-light-purple);
}

.bg-blue-slight-light {
    background-color: var(--color-blue-light);
}

.text-primary-light {
    color: var(--color-primary-light) !important;
}

.text-secondary {
    color: var(--color-secondary) !important;
}

.text-secondary-light {
    color: var(--color-secondary-light) !important;
}

.text-danger {
    color: var(--color-danger) !important;
}

.text-danger-light {
    color: var(--color-danger-light-dark) !important;
}

.text-light-orange {
    color: var(--color-light-orange);
}

.text-warning {
    color: var(--color-warning)
}

.bg-primary {
    background: var(--color-primary) !important;
}

.bg-tertiary {
    background: var(--color-tertiary);
}

.bg-gradient {
    background: var(--talenlio-background-gradient-color);
}

.bg-primary-light {
    background: var(--color-primary-light) !important;
}

.bg-secondary {
    background: var(--color-secondary) !important;
}

.bg-secondary-light {
    background: var(--color-secondary-light) !important;
}

.bg-danger {
    background: var(--color-danger) !important;
}

.bg-danger-light {
    background: var(--color-danger-light);
}

.bg-danger-primary {
    background: var(--color-danger-primary) !important;
}

.bg-gray {
    background: var(--color-stroke);
}

.bg-blue-light {
    background-color: rgb(219, 234, 254);
}

html, body {
    font-family: 'Poppins', sans-serif !important;
    height: 100%;
}

body {
    padding: 0 0 env(safe-area-inset-bottom);
    background-image: url('assets/images/talenlio-background-image.avif');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    width: 100%;
    height: 100%;
}

.horizontal-separator {
    border-top: solid 1px var(--color-stroke);
}

owl-carousel-o {
    height: 100%;
    width: 100%;
    position: inherit !important;
}

.owl-carousel .owl-stage-outer {
    height: 100%;
    width: 100%;
}

.owl-stage {
    height: 100%;
    width: 100%;
}

.card-blur-background {
    border: 2px solid var(--color-border-gray);
    background: var(--color-background-blur);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.7px);
    -webkit-backdrop-filter: blur(7.7px);
}

.shimmer-card-container {
    will-change: transform;
    animation: placeHolderShimmer 1.2s linear infinite forwards;
    background: linear-gradient(90deg, var(--color-light-grey) 25%, var(--color-white-blue-light) 50%, var(--color-light-grey) 75%);
    background-size: 200% 100%;
}

.form-card {
    background: var(--color-white);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-background-blur);
}

.box-shadow {
    box-shadow: var(--color-card-box-shadow-dark) 0 48px 100px 0;
}

.white-blur-background-card {
    border-radius: 16px;
    border: 1px solid var(--color-white);
    background: var(--color-background-blur);
    box-shadow: var(--card-box-shadow);
    backdrop-filter: blur(2px);
}

//custom-snack-bar
.snack-bar-container {
    border-radius: 14px !important;
    padding: 4px !important;
    background: var(--color-white);
    color: var(--color-black);
    box-shadow: none !important;
}

.welcome-snack-bar {
    border: 2px solid var(--color-primary);
}

.warning-snack-bar {
    border: 2px solid var(--color-warning);
}

.info-snack-bar {
    border: 2px solid var(--color-primary);
}

.success-snack-bar {
    border: 2px solid var(--color-success-dark-light);
}

.danger-snack-bar {
    border: 2px solid var(--color-danger);
}

// custom stepper
.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover:not([aria-disabled]),
.mat-step-header:hover[aria-disabled=false] {
    background-color: transparent;
}

.mat-step-header .mat-step-label {
    color: var(--color-grey-light-dark);
    min-width: 120px !important;
}

.mat-step-label-active {
    color: var(--color-primary) !important;
}

.mat-step-header .mat-step-icon {
    background-color: var(--color-grey-light-dark);
    color: white;
}

.mat-step-header .mat-step-icon-state-edit {
    background-color: #7faefc;
    color: white;
}

.mat-step-header .mat-step-icon-selected {
    background-color: var(--color-primary);
    color: white;
}

.custom-chip {
    background: var(--color-alice-blue) !important;
    border: 1px solid var(--color-stroke);
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--color-grey-dark);
    height: max-content !important;
}

.box-shadow-dark {
    box-shadow: 0 2px 2px 0 var(--black-transparent-14), 0 3px 1px -2px var(--black-transparent-2), 0 1px 5px 0 var(--black-transparent-12);
}

.sticky-button {
    position: sticky;
    background: var(--color-white);
    z-index: 999;
    top: 0;
}

// TABLE --------

table {
    width: 100%;
    border: solid 1px var(--color-stroke) !important;
    border-radius: 12px !important;
}

.selected-row {
    background: var(--color-border-gray);
}

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    border-bottom-color: var(--color-stroke);
}

th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-right: 24px;
}

.mat-dialog-container {
    padding: 0 !important;
}

.mat-dialog-content {
    margin: 0 !important;
}

.common-menu-item, .common-menu-item:hover {
    color: var(--color-grey-dark);
    background-color: transparent;
}

.danger-menu-item, .danger-menu-item:hover {
    color: var(--color-danger-light-dark);
    background-color: transparent;
}

.custom-snackbar {
    background: unset !important;
    box-shadow: none !important;
    color: unset !important;
    padding: 0 !important;
}

:host ::ng-deep .text-primary {
    color: var(--color-primary);
}

host ::ng-deep .border-primary {
    border-color: var(--color-primary);
}

host ::ng-deep .bg-primary {
    background-color: var(--color-primary);
}

host ::ng-deep .bg-primary-light {
    background-color: var(--color-primary-light);
}

host ::ng-deep .text-secondary {
    background-color: var(--color-secondary);
}

host ::ng-deep .border-secondary {
    border-color: var(--color-secondary);
}

host ::ng-deep .bg-secondary {
    background-color: var(--color-secondary);
}

host ::ng-deep .bg-secondary-light {
    background-color: var(--color-secondary-light);
}

:host ::ng-deep .text-success {
    color: var(--color-success) !important;
}

:host ::ng-deep .border-success {
    border-color: var(--color-success) !important;
}

:host ::ng-deep .bg-success {
    background-color: var(--color-success) !important;
}

:host ::ng-deep .bg-success-light {
    background-color: var(--color-success-light) !important;
}

:host ::ng-deep .border-warning {
    border-color: var(--color-warning) !important;
}

:host ::ng-deep .bg-warning {
    background-color: var(--color-warning) !important;
}

:host ::ng-deep .bg-warning-light {
    background-color: var(--color-warning-light) !important;
}

:host ::ng-deep .text-danger {
    color: var(--color-danger) !important;
}

:host ::ng-deep .border-danger {
    border-color: var(--color-danger) !important;
}

:host ::ng-deep .bg-danger {
    background-color: var(--color-danger) !important;
}

:host ::ng-deep .bg-danger-light {
    background-color: var(--color-danger-light) !important;
}

:host ::ng-deep .text-black {
    color: var(--color-black) !important;
}

:host ::ng-deep .border-black {
    border-color: var(--color-black) !important;
}

:host ::ng-deep .bg-black {
    background-color: var(--color-black) !important;
}

:host ::ng-deep .bg-black-light {
    background-color: var(--color-black-light) !important;
}

:host ::ng-deep .text-white {
    color: var(--color-white) !important;
}

:host ::ng-deep .border-white {
    border-color: var(--color-white) !important;
}

:host ::ng-deep .bg-white {
    background-color: var(--color-white) !important;
}

:host ::ng-deep .bg-white-light {
    background-color: var(--color-white-light) !important;
}

.fade-in {
    animation: fadeIn 300ms;
}

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade-down {
    0% {
        opacity: 0;
        transform: translateY(-30px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fade-left {
    0% {
        opacity: 0;
        transform: translateX(60px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fade-right {
    0% {
        opacity: 0;
        transform: translateX(-60px) scale(0.9);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
