.border-primary {
    border: 1px solid var(--color-primary) !important;
}

.border-primary-light {
    border: 1px solid var(--color-primary-light) !important;
}

.border-secondary {
    border: 1px solid var(--color-secondary) !important;
}

.border-secondary-light {
    border: 1px solid var(--color-secondary-light) !important;
}

.border-danger {
    border: 1px solid var(--color-danger) !important;
}

.border-stroke {
    border: 1px solid var(--color-stroke);
}

.border-bottom-stroke {
    border-bottom: 1px solid var(--color-stroke);
}

.border-top-stroke {
    border-top: 1px solid var(--color-stroke);
}

.border-stroke-dark {
    border: 1px solid var(--color-stroke-shadow);
}

.border-danger-light {
    border: 1px solid var(--color-danger-light) !important;
}
